.outer {
  position: fixed;
  top: 0; left: 0; right: 0; bottom: 0;
  z-index: 100000;
  .background {
    background-color: white;
    opacity: .5;
    position: absolute;
    top: 0; left: 0; right: 0; bottom: 0;
  }

  .popup {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 600px;
    height: 400px;
    margin-left: -300px;
    margin-top: -200px;
    background-color: white;
    text-align: center;
    padding: 20px;
  }
}
